import { Button, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { MainContext } from '../../App';





const HoaDocsComponent = () => {
  const { user, setUser } = React.useContext(MainContext);

  return (
    <span>
      {
        user.authenticated &&
        <span>
          <Typography align='center' variant='h4'>Nuestras contratos de HOA</Typography>
          <Typography align='center' variant='h4'>Our HOA Contracts </Typography>
        </span>
      }
    </span>
  );
};
export default HoaDocsComponent;
