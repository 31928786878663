import * as React from 'react';
import { MainContext } from '../../App';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const VendorsComponent = () => {
  const { user, setUser } = React.useContext(MainContext);
  const isAuthenticated = true;
  const username = user.username;

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


  const [expandedId, setExpandedId] = React.useState(-1);

  const handleExpandClick = (i: React.SetStateAction<number>) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  return (
    <>
      <div className="bg-slate-50">
        <Typography variant="h3" gutterBottom align="center">
          Vendors
        </Typography>
      </div>
      <Grid container
        justifyContent="center" >
        {
          user.authenticated &&

          <Box sx={{
            p: '20px',

          }}>
            <Card sx={{ maxWidth: 345, minWidth: 345 }}>
              <CardHeader
                // avatar={
                //   <Avatar sx={{ bgcolor: red[500] }} aria-label="Plumber">
                //     Handyman
                //   </Avatar>
                // }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title="Nombre"
                subheader="Handyman"
              />
              {/* <CardMedia
              component="img"
              height="194"
              image="/static/images/cards/paella.jpg"
              alt="Paella dish"
            /> */}
              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                  WhatsApp:303-555-1212
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
                {/* <IconButton aria-label="share">
                <ShareIcon />
              </IconButton> */}
                <ExpandMore
                  expand={expandedId === 1}
                  onClick={() => handleExpandClick(1)}
                  aria-expanded={expandedId === 1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expandedId === 1} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Mas Information</Typography>
                  <Typography paragraph>
                  Handyman that can do plumbing, small electrical, appliance repair  

                  </Typography>

                </CardContent>
              </Collapse>
            </Card>
          </Box>
        }
        {
          user.authenticated &&

          <Box sx={{
            p: '20px',

          }}>
            <Card sx={{ maxWidth: 345, minWidth: 345 }}>
              <CardHeader
                // avatar={
                //   <Avatar sx={{ bgcolor: red[500] }} aria-label="Plumber">
                //     Handyman
                //   </Avatar>
                // }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title="Nombre"
                subheader="Plumber "
              />
              {/* <CardMedia
              component="img"
              height="194"
              image="/static/images/cards/paella.jpg"
              alt="Paella dish"
            /> */}
              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                  WhatsApp:303-555-1212
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
                {/* <IconButton aria-label="share">
                <ShareIcon />
              </IconButton> */}
                <ExpandMore
                  expand={expandedId === 2}
                  onClick={() => handleExpandClick(2)}
                  aria-expanded={expandedId === 2}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expandedId === 2} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Mas Information</Typography>
                  <Typography paragraph>
                   Only does plumbing

                  </Typography>

                </CardContent>
              </Collapse>
            </Card>
          </Box>
        }
      </Grid>
    </>

  );
};
export default VendorsComponent;
