import { StyledEngineProvider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AnnouncementsComponent from './components/announcements/announcements';
import LoginComponent from './components/auth/login';
import { LoginService } from './components/auth/login-service';
import Header from './components/header/header';
import HoaDocsComponent from './components/hoadocs/hoadocs';
import VendorsComponent from './components/vendors/vendors';

interface IUser {
  authenticated: boolean,
  username: string,
  loading: boolean
}

interface IUserContext {
  user: IUser;
  setUser: (user: IUser) => void;
}

const defaultState = {

  user: {
    authenticated: false,
    username: '',
    loading: false
  },
  setUser: (user: IUser) => {
    user = user;
    console.log('MCS');
  }

};

let userData:IUser = {
  authenticated: false,
  username: "",
  loading: false
};
export const MainContext = React.createContext<IUserContext>(defaultState);

function App() {
  useEffect(() => {
    console.log('Component mounted');
    
    const loginService = new LoginService();
    const localusername = loginService.getLocalUsername();
    const isAuth = loginService.getLocalIsAuth();
   
    if (isAuth === '1') {
      const un = loginService.getLocalUsername();
      if(un != null){
        setUser({
          authenticated: true,
          username: un,
          loading: false
        });
      }
  
    }

    return () => {
        console.log('Component will be unmount')
    }
}, []);
  const [user, setUser] = useState<IUser>(userData);
  return (
    <>

      <Router>
        <React.StrictMode>
          <StyledEngineProvider injectFirst>
            <MainContext.Provider value={{
              user,
              setUser
            }}>
              
            <Header />
            {
              !user.authenticated &&
              <LoginComponent />
            }


            <Routes>
              <Route path="/documents" element={<HoaDocsComponent />} />
              <Route path="/announcements" element={<AnnouncementsComponent />} />
              <Route path="/" element={<AnnouncementsComponent />} />
              <Route path="/repairs" element={<VendorsComponent />} />
            </Routes>

          </MainContext.Provider>
        </StyledEngineProvider>
      </React.StrictMode>

      {/* */}

    </Router>
    </>
  );
}

export default App;


// import {useEffect, useState} from 'react';

// const App = () => {
//   // 👇️ const employee: {name: string; salary: number;}
//   const [employee, setEmployee] = useState<{name: string; salary: number}>({
//     name: '',
//     salary: 0,
//   });

//   useEffect(() => {
//     setEmployee({name: 'James', salary: 100});
//   }, []);

//   return (
//     <div>
//       <h2>Name: {employee.name}</h2>
//       <h2>Salary: {employee.salary}</h2>
//     </div>
//   );
// };

// export default App;
