import { Button, TextField, Alert, Box } from '@mui/material';
import * as React from 'react';
import { MainContext } from '../../App';
import { LoginService } from './login-service';
import { useState, useEffect } from 'react';




const LoginComponent = () => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);

  const { user, setUser } = React.useContext(MainContext);

  let hideLogin = true;
  const loginService = new LoginService();
  const localusername = loginService.getLocalUsername();
  const isAuth = loginService.getLocalIsAuth();

  if (isAuth === '1') {
    hideLogin = true;
  } else {
    hideLogin = false;
  }



  useEffect(() => {

    return () => {
      console.log('Component will be unmount')
    }
  }, []);


  const handleSubmit = (event: { preventDefault: () => void; }) => {
    // Prevent page reload    
    event.preventDefault();
    if (username.length > 0 && password.length > 0) {
      if (setUser) {
        setUser({
          authenticated: false,
          username: '',
          loading: true
        });
        const service = new LoginService();
        service.authenticate(username, password).then(response => {
          if (response.data) {
            setUser({
              authenticated: true,
              username: username,
              loading: false
            });
            service.saveLocalAuth();
            service.saveLocalJwt(response.data, username);
            setError(false);
          } else {
            setError(true);
          }
        });


      }
    }
  };

  return (

    <form className="form" onSubmit={handleSubmit}>

      {
        error &&
        <Alert variant="filled" severity="error">
          Invalid username or password!
        </Alert>
      }
      {
        !hideLogin &&


        <div className="flex items-center justify-center min-h-screen">
          <div className="px-8 py-6 mt-4 text-left  shadow-lg">
            <Box
              component="img"
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="Torres Cantera"
              src="./TorresCanteraLogoBgWhite.png"
            />
            <h3 className="text-2xl font-bold text-center">Login to Torres Cantera</h3>

            <div className="mt-4">
              <div>
                <input type="text" placeholder="Username" value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
              </div>
              <div className="mt-4">
                <input type="password" placeholder="Password" value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
              </div>
              <div className="flex items-baseline justify-between">
                <button className="px-6 py-2 mt-4 text-white bg-blue-600 disabled
              rounded-lg hover:bg-blue-900">Login
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </form>

  );
};
export default LoginComponent;
