import * as React from 'react';
import { MainContext } from '../../App';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const AnnouncementsComponent = () => {
  const { user, setUser } = React.useContext(MainContext);
  const isAuthenticated = true;
  const username = user.username;

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


  const [expandedId, setExpandedId] = React.useState(-1);

  const handleExpandClick = (i: React.SetStateAction<number>) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  return (
    <>
     <div className="bg-slate-50">
      <Typography variant="h3" gutterBottom align="center">
        Announcements
      </Typography>
      </div>
      <Grid container  
  justifyContent="center" >
        {
          user.authenticated &&

          <Box sx={{
            p: '20px',

          }}>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader 
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    1
                  </Avatar>
                }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title="Protocolos para Ingresar: WhatsApp (612-159-3524)"
                subheader="September 15, 2022"
              />
              {/* <CardMedia
              component="img"
              height="194"
              image="/static/images/cards/paella.jpg"
              alt="Paella dish"
            /> */}
              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                  Favor de leer los nuevos protocolos implementados para los guardias, estos se han activado para la  seguridad de los residentes:
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                {/* <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton> */}
                <ExpandMore
                  expand={expandedId === 1}
                  onClick={() => handleExpandClick(1)}
                  aria-expanded={expandedId === 1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expandedId === 1} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Protocolos para Ingresar</Typography>
                  <Typography paragraph>
                    1. Saludar
                    2. Preguntar si es Residente o Visitante
                    3. Si es residente, solicite el nombre y el número de la casa (confirme que es el propietario real)
                    4. Si es Visitante, pregunte por
                    a. Nombre y número de casa o condominio que visita
                    b. Recoger y conservar la identificación
                    C. Registrar el nombre del visitante
                    d. Descripción del vehículo que incluye marca, modelo, color y número de placa
                    e. Número de personas en el vehículo o caminando en la comunidad
                    f. El propietario debe confirmar a su invitado con la seguridad a través de WhatsApp (612-159-3524) o no se les permite ingresar.
                    g. Registrar hora de entrada
                    5. Además,  los propietarios de Condominios y visitantes:
                    a. Notifique a la seguridad secundaria del condominio que espera un vehículo para asegurarse de que el propietario o invitado no permanezca en el área residencial
                    6. Los repartidores y los repartidores de alimentos se consideran visitantes y el personal de seguridad debe recopilar una identificación antes de ingresar.

                  </Typography>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Protocolos para Salida</Typography>
                  <Typography paragraph>
                    1. Saludar
                    2. Si es residente, abra la puerta para salir
                    3. Si es visitante:
                    a. Devolución  de ID
                    b. Registrar fecha y hora de salida
                    C. Confirmar el mismo número de visitantes que salen en el vehículo

                  </Typography>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Registro de Visitantes</Typography>
                  <Typography paragraph>
                    Todos los visitantes deben ser registrados en el registro con:
                    1. Nombre
                    2. Hora de entrada
                    3. Descripción del vehículo, incluida la marca, el modelo, el color y el número de placa
                    4. Número de personas
                    5. Nombre y número de casa o condominio visitado
                    6. Hora de salida
                    * El registro de visitantes es obligatorio y será revisado con frecuencia por los supervisores y cualquier miembro de la junta y/o administración de HOA

                  </Typography>

                </CardContent>
              </Collapse>
            </Card>
          </Box>
        }
        {
          user.authenticated &&

          <Box sx={{
            p: '20px'
          }}>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    1
                  </Avatar>
                }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title="Protocols to Enter: WhatsApp (612-159-3524)"
                subheader="September 15, 2022"
              />
              {/* <CardMedia
              component="img"
              height="194"
              image="/static/images/cards/paella.jpg"
              alt="Paella dish"
            /> */}
              <CardContent>
                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                  Please read the new protocols implemented for the guards, these have been activated for the safety of the residents:
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                {/* <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton> */}
                <ExpandMore
                  expand={expandedId === 2}
                  onClick={() => handleExpandClick(2)}
                  aria-expanded={expandedId === 2}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expandedId === 2} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Protocols to Enter</Typography>
                  <Typography paragraph>
                    1.    Greet
                    2.    Ask if Resident or Visitor
                    3.    If Resident, ask for name and house number (confirm they are actual owner)
                    4.    If Visitor, ask for
                    a.    Name and house or condo number visiting
                    b.    Collect and hold ID
                    c.     Record visitor name
                    d.    Vehicle description including make, model, color, and license plate number
                    e.    Number of people in vehicle or walking into community
                    f.     Owner needs to confirm their guest with security via WhatsApp (612-159-3524) or they are not allowed in.
                    g.    Record time of entry
                    5.    Additionally, if Condo owners and visitors:
                    a.    Notify secondary condo security to expect vehicle to assure that owner or guest does not remain in the residential area
                    6.     Delivery drivers and food delivery are considered visitors and security needs to collect ID before entering.

                  </Typography>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Protocols for Exit</Typography>
                  <Typography paragraph>
                    1.    Greet
                    2.    If Resident, open gate to exit
                    3.    If Visitor:
                    a.    Return ID
                    b.    Record date and time of exit
                    c.     Confirm same number of visitors leaving in vehicle


                  </Typography>
                  <Typography paragraph sx={{ fontWeight: 'bold' }}>Visitor Log</Typography>
                  <Typography paragraph>
                    All visitors must be registered in the log with:
                    1.    Name
                    2.    Entry time
                    3.    Vehicle description including make, model, color, and license plate number
                    4.    Number of people
                    5.    Name and number of house or condo visited
                    6.    Departure time
                    * Visitor Log is mandatory and will be reviewed frequently by supervisors and any members of HOA board and/or administration

                  </Typography>

                </CardContent>
              </Collapse>
            </Card>
          </Box>
        }
      </Grid>
    </>

  );
};
export default AnnouncementsComponent;
