import axios from "axios";

export class LoginService {
    constructor() {

    }

    public async authenticate(username: string, password: string): Promise<any> {
        username = encodeURIComponent(username);
        password = encodeURIComponent(password);
        let url = `https://hoa-functions20220905144011.azurewebsites.net/api/authenticate?un=${username}&pw=${password}`;       
        return await axios.get(url);
    }

    public saveLocalAuth() {
        localStorage.setItem('jdihoa', '1');
    }

    public saveLocalJwt(jwt:string, username: string) {
        localStorage.setItem('jdihoa-jwt', jwt);
        localStorage.setItem('jdihoa-username', username);
    }

    public clearLocalAuth() {
        localStorage.removeItem('jdihoa');
    }

    public getLocalUsername(): string | null {
        return localStorage.getItem('jdihoa-username');
    }

    public getLocalIsAuth(): string | null {
        return localStorage.getItem('jdihoa');
    }



}