import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { createSvgIcon } from '@mui/material';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Route, Routes, Link } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MainContext } from '../../App';




const pages = ['Documents', 'Announcements', 'Repairs'];
// const settings = ['Profile', 'Account', 'Logout'];
let settings = [''];

const HomeIcon = createSvgIcon(
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 512 512"  >
    <g>
      <g>
        <path d="M256,0c-31.535,0-57.191,25.657-57.191,57.192c0,31.535,25.657,57.191,57.191,57.191s57.191-25.657,57.191-57.191
			C313.191,25.657,287.535,0,256,0z M256,98.043c-22.526,0-40.851-18.325-40.851-40.851S233.474,16.34,256,16.34
			s40.851,18.325,40.851,40.851S278.526,98.043,256,98.043z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M503.83,392.17h-92.596V272.34h19.064c37.543,0,68.085-30.542,68.085-68.085v-16.34h2.723c4.513,0,8.17-3.657,8.17-8.17
			s-3.657-8.17-8.17-8.17h-2.723v-16.34h2.723c4.513,0,8.17-3.657,8.17-8.17s-3.657-8.17-8.17-8.17h-2.723v-24.511
			c0-15.017-12.217-27.234-27.234-27.234s-27.234,12.217-27.234,27.234v8.17h-2.723c-4.513,0-8.17,3.657-8.17,8.17
			c0,4.513,3.657,8.17,8.17,8.17h2.723v65.362c0,7.509-6.108,13.617-13.617,13.617h-19.064V51.745h2.723
			c4.513,0,8.17-3.657,8.17-8.17c0-4.513-3.657-8.17-8.17-8.17h-2.723v-8.17C411.234,12.217,399.017,0,384,0
			s-27.234,12.217-27.234,27.234v24.511h-2.723c-4.513,0-8.17,3.657-8.17,8.17s3.657,8.17,8.17,8.17h2.723v236.936h-19.064
			c-7.509,0-13.617-6.108-13.617-13.617v-65.362h2.723c4.513,0,8.17-3.657,8.17-8.17c0-4.513-3.657-8.17-8.17-8.17h-2.723v-8.17
			c0-15.017-12.217-27.234-27.234-27.234s-27.234,12.217-27.234,27.234v8.17h-2.723c-4.513,0-8.17,3.657-8.17,8.17
			c0,4.513,3.657,8.17,8.17,8.17h2.723v16.34h-2.723c-4.513,0-8.17,3.657-8.17,8.17c0,4.513,3.657,8.17,8.17,8.17h2.723v32.681
			c0,37.543,30.542,68.085,68.085,68.085h19.064v144.34c0,4.513,3.657,8.17,8.17,8.17s8.17-3.657,8.17-8.17V351.319
			c0-4.513-3.657-8.17-8.17-8.17h-27.234c-28.531,0-51.745-23.213-51.745-51.745v-89.872c0-6.007,4.887-10.894,10.894-10.894
			c6.007,0,10.894,4.887,10.894,10.894v89.872c0,16.518,13.439,29.957,29.957,29.957h27.234c4.513,0,8.17-3.657,8.17-8.17V27.234
			c0-6.007,4.887-10.894,10.894-10.894s10.894,4.887,10.894,10.894v198.808c0,4.513,3.657,8.17,8.17,8.17h27.234
			c16.518,0,29.957-13.439,29.957-29.957v-89.872c0-6.007,4.887-10.894,10.894-10.894c6.007,0,10.894,4.887,10.894,10.894v89.872
			c0,28.531-23.213,51.745-51.745,51.745h-27.234c-4.513,0-8.17,3.657-8.17,8.17v239.66c0,4.513,3.657,8.17,8.17,8.17
			s8.17-3.657,8.17-8.17v-95.319h92.596c4.513,0,8.17-3.657,8.17-8.17C512,395.827,508.343,392.17,503.83,392.17z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M332.255,392.17h-93.966c0.897-4.401,1.37-8.955,1.37-13.617v-32.681h2.723c4.513,0,8.17-3.657,8.17-8.17
			s-3.657-8.17-8.17-8.17h-2.723v-16.34h2.723c4.513,0,8.17-3.657,8.17-8.17c0-4.513-3.657-8.17-8.17-8.17h-2.723v-8.17
			c0-15.017-12.217-27.234-27.234-27.234s-27.234,12.217-27.234,27.234v8.17h-2.723c-4.513,0-8.17,3.657-8.17,8.17
			c0,4.513,3.657,8.17,8.17,8.17h2.723v65.362c0,7.509-6.108,13.617-13.617,13.617h-19.064V187.915h2.723
			c4.513,0,8.17-3.657,8.17-8.17s-3.657-8.17-8.17-8.17h-2.723v-16.34h2.723c4.513,0,8.17-3.657,8.17-8.17s-3.657-8.17-8.17-8.17
			h-2.723v-24.511c0-15.017-12.217-27.234-27.234-27.234s-27.234,12.217-27.234,27.234v8.17h-2.723c-4.513,0-8.17,3.657-8.17,8.17
			c0,4.513,3.657,8.17,8.17,8.17h2.723v166.128H78.979c-7.509,0-13.617-6.108-13.617-13.617v-65.362h2.723
			c4.513,0,8.17-3.657,8.17-8.17c0-4.513-3.657-8.17-8.17-8.17h-2.723v-8.17c0-15.017-12.217-27.234-27.234-27.234
			s-27.234,12.217-27.234,27.234v24.511H8.17c-4.513,0-8.17,3.657-8.17,8.17s3.657,8.17,8.17,8.17h2.723v16.34H8.17
			c-4.513,0-8.17,3.657-8.17,8.17s3.657,8.17,8.17,8.17h2.723v16.34c0,37.543,30.542,68.085,68.085,68.085h19.064v32.681H8.17
			c-4.513,0-8.17,3.657-8.17,8.17c0,4.513,3.657,8.17,8.17,8.17h89.872v95.319c0,4.513,3.657,8.17,8.17,8.17
			c4.513,0,8.17-3.657,8.17-8.17V351.319c0-4.513-3.657-8.17-8.17-8.17H78.979c-28.531,0-51.745-23.213-51.745-51.745v-89.872
			c0-6.007,4.887-10.894,10.894-10.894c6.007,0,10.894,4.887,10.894,10.894v89.872c0,16.518,13.439,29.957,29.957,29.957h27.234
			c4.513,0,8.17-3.657,8.17-8.17V114.383c0-6.007,4.887-10.894,10.894-10.894c6.007,0,10.894,4.887,10.894,10.894V400.34
			c0,4.513,3.657,8.17,8.17,8.17h27.234c16.518,0,29.957-13.439,29.957-29.957v-89.872c0-6.007,4.887-10.894,10.894-10.894
			s10.894,4.887,10.894,10.894v89.872c0,28.531-23.213,51.745-51.745,51.745H144.34c-4.513,0-8.17,3.657-8.17,8.17v65.362
			c0,4.513,3.657,8.17,8.17,8.17c4.513,0,8.17-3.657,8.17-8.17v-57.191h19.064c26.797,0,50.019-15.567,61.122-38.128h99.559
			c4.513,0,8.17-3.657,8.17-8.17C340.426,395.827,336.769,392.17,332.255,392.17z"/>
      </g>
    </g>
    <g>
      <g>
        <circle cx="291.404" cy="435.745" r="8.17" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="324.085" cy="468.426" r="8.17" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="49.021" cy="441.191" r="8.17" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="462.979" cy="462.979" r="8.17" />
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>,
  'Home',
);

const ResponsiveAppBar = () => {
  const { user, setUser } = React.useContext(MainContext);
  const username = user.username;
  settings = [];
  settings.push("House:"+username);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);



  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {

    settings = [];
    settings.push(username);

    return () => {
      console.log('Component will be unmount')
    }
  }, []);

  return (

    <AppBar position="static">
      <Container maxWidth="xl">

        {

          user.authenticated &&
          <Toolbar disableGutters>
            <HomeIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              TC
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu} component={Link}
                    to={page.replace(/\s/g, "")}>
                    <Typography textAlign="center">{page }</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <HomeIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              TC
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button component={Link} to={page.replace(/\s/g, "")}
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>

        }
      </Container>
      {
        user.loading &&
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
          <LinearProgress color="secondary" />
        </Stack>
      }

    </AppBar>




  );
};
export default ResponsiveAppBar;
